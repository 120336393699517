import axios from 'axios';
import {
  IAppConfig,
  IAppVersion,
  IProject,
  ICurrentUser,
  IUser, 
  IUsers
} from '../data-models/dataModels';
import store from '../redux/store';

const ApplicationService = {
  getAppConfig: async (): Promise<IAppConfig> => {
    const response = await axios.get<IAppConfig>(
      `/config/AppConfigClient.json`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    );
    return response.data;
  },
  getAppVersion: async (): Promise<IAppVersion> => {
    const response = await axios.get<IAppVersion>(`/config/version.json`, {
      headers: { 'Cache-Control': 'no-cache' }
    });
    return response.data;
  },
  getPermissions: async (): Promise<string[]> => {
    const {
      config: { baseUrl }
    } = store.getState();

    const response = await axios.get<string[]>(
      `${baseUrl}/api/users/permissions`,
      { withCredentials: true }
    );
    return response.data;
  },
  getUser: async (projectName?: string): Promise<any> => {
    const {
      config: { baseUrl }
    } = store.getState();
    let header = {};
    if (projectName) {
      header = {
        projectName
      };
    }

    const response = await axios.get<ICurrentUser>(
      `${baseUrl}/api/users/currentUser`,
      { withCredentials: true, headers: header }
    );
    if (response.data!.csrfToken) {
      axios.defaults.headers.common['X-XSRF-TOKEN'] = response.data!.csrfToken;
    }
    return response.data;
  },
  getUsers: async (): Promise<IUsers[]> => {
    const {
      config: { baseUrl },
    } = store.getState();

    const response = await axios.get(
      `${baseUrl}/api/comment/getUsers`,
      { withCredentials: true } // Sending session cookies
    );
    return response.data;
  },
  getChangelog: async (): Promise<string> => {
    const response = await axios.get<string>(`/CHANGELOG.md`);
    return response.data;
  },
  getProjectList: async (projectName?: string): Promise<IProject[]> => {
    const {
      config: { baseUrl }
    } = store.getState();
    let header = {};
    if (projectName) {
      header = {
        projectName
      };
    }
    const response = await axios.get<IProject[]>(
      `${baseUrl}/api/users/getProjectList`,
      { withCredentials: true, headers: header }
    );
    return response.data;
  },

  getFavouriteModel: async (): Promise<number> => {
    const {
      config: { baseUrl }
    } = store.getState();

    const response = await axios.get<string>(
      `${baseUrl}/api/users/favourite-model`,
      { withCredentials: true }
    );
    return parseInt(response.data);
  },
  setFavouriteModel: async (
    modelGroup: number,
    user: IUser
  ): Promise<number> => {
    const {
      config: { baseUrl }
    } = store.getState();

    const response = await axios.put<number>(
      `${baseUrl}/api/users/favourite-model`,
      { modelGroup, user },
      { withCredentials: true }
    );
    return response.data;
  },
  removeFavouriteModel: async (): Promise<number> => {
    const {
      config: { baseUrl }
    } = store.getState();

    const response = await axios.delete<number>(
      `${baseUrl}/api/users/favourite-model`,
      { withCredentials: true }
    );
    return response.data;
  },
  getNotificationData: async (): Promise<any> => {
    const {
      config: { baseUrl }
    } = store.getState();

    const response = await axios.get<string>(
      `${baseUrl}/api/users/notification-data`,
      { withCredentials: true }
    );
    return response.data;
  },
  getWPCreatedByUser : async(createdBy : number,id:number) : Promise<any> => {
    const {
      config: { baseUrl }
    } = store.getState();
    try {
      const response = await axios.get(
        `${baseUrl}/api/users/getcreatedByWPName/` + createdBy + '/' + id,
        { withCredentials: true } // Sending session cookies
      );
      return response.data;
    } catch (e) {
      throw new Error(JSON.stringify((e as any).response.data));
    }
  },
  getFeaturAccessForExternal : async(email : string) : Promise<any> => {
    const {
      config: { baseUrl }
    } = store.getState();
    try{
      const response = await axios.get(
        `${baseUrl}/api/users/featureAcces`,
        { withCredentials: true , params : { email : email }}
      );
      return response.data; 
    } catch(e) {
      throw new Error(JSON.stringify((e as any).response.data));
    }
   
  },
 
  checkTokenIsPresent : async(email : string) : Promise<any> => {
    const {
      config: { baseUrl }
    } = store.getState();
    try{
      const response = await axios.get(
        `${baseUrl}/api/users/checkTokenPresent/` + email,
        { withCredentials: true }
      );
      return response.data; 
    } catch(e) {
      throw new Error(JSON.stringify((e as any).response.data));
    }
  },

  getExternalUserData: async (email:string) => {
    const {
      config: { baseUrl }
    } = store.getState();
    const response = await axios.post(
      `${baseUrl}/api/getExternalUser/${email}`,
      { withCredentials: true } // Sending session cookies
    );
    return response.data;
  }
  
};

export default ApplicationService;
