import { IPanel } from '../data-models/dataModels';
import {
  ViewerState,
  IConfigState,
  IDndState,
  IPermissionsState,
  IWPState,
  ICommentState,
  ITourState,
  IPushpinOpenData,
  IPushpinCommentOpenData,
  IPushpinComments
} from './stateTypes';

export const SELECT_MODELS = 'SELECT_MODELS';
export const SET_MODEL_OFFSET = 'SET_MODEL_OFFSET';
export const SET_VIEWER_INSTANCE = 'SET_VIEWER_INSTANCE';
export const SET_MODEL_PROPERTIES = 'SET_MODEL_PROPERTIES';
export const COLOR_OBJETS_MODEL = 'COLOR_OBJETS_MODEL';
export const COLOR_BI_TAGS = 'COLOR_BI_TAGS';
export const INIT_CONFIG = 'INIT_CONFIG';
export const OPEN_PANEL = 'OPEN_PANEL';
export const SELECT_TAGS = 'SELECT_TAGS';
export const SELECT_TAG_NAMES = 'SELECT_TAG_NAMES';
export const SELECT_AGGREGATE_TAGS = 'SELECT_AGGREGATE_TAGS';
export const ISOLATE_TAGS = 'ISOLATE_TAGS';
export const ISOLATE_TAG_NAMES = 'ISOLATE_TAG_NAMES';
export const SELECT_OBJECTS = 'SELECT_OBJECTS';
export const ISOLATE_OBJECTS = 'ISOLATE_OBJECTS';
export const CUTPLANES_CHANGED = 'CUTPLANES_CHANGED';
export const SELECT_CUTPLANES = 'SELECT_CUTPLANES';
export const EDIT_CUTPLANES = 'EDIT_CUTPLANES';
export const SELECTED_OBJECT_IN_VIEWER = 'SELECTED_OBJECT_IN_VIEWER';
export const CAMERA_POSITION_CHANGED = 'CAMERA_POSITION_CHANGED';
export const EXTENSION_ACTIVE_CHANGED = 'EXTENSION_ACTIVE_CHANGED';
export const CHANGE_POSITION_CAMERA = 'CHANGE_POSITION_CAMERA';
export const CREATE_SNAPSHOT = 'CREATE_SNAPSHOT';
export const SELECTED_STATUS_MODES = 'SELECTED_STATUS_MODES';
export const SET_CUSTOM_ENTITES_COLORED = 'SET_CUSTOM_ENTITES_COLORED';
export const SELECT_ISOLATE_WP = 'SELECT_ISOLATE_WP';
export const SET_PREV_WP = 'SET_PREV_WP'
export const OPEN_BI_REPORT = 'OPEN_BI_REPORT';
export const SET_SCREENSHOT_ACTION = 'SET_SCREENSHOT_ACTION';
export const SAVE_VIEWER_SETTINGS = 'SAVE_VIEWER_SETTINGS';
export const APPLY_VIEWER_SETTINGS = 'APPLY_VIEWER_SETTINGS';
export const SET_SCREENSHOT_URL = 'SET_SCREENSHOT_URL';
export const SET_MAX_SELECTION_LEVEL = 'SET_MAX_SELECTION_LEVEL';
export const CHANGE_SELECTION_LEVEL = 'CHANGE_SELECTION_LEVEL';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_LESSTHANXSMALL = 'SET_IS_LESSTHANXSMALL';
export const SET_PANEL_SCROLL_POSITION = 'SET_PANEL_SCROLL_POSITION';
export const SET_PREV_Comment = 'SET_PREV_Comment';
export const SELECT_ISOLATE_Comment = 'SELECT_ISOLATE_Comment';
export const UPDATE_PANEL = 'UPDATE_PANEL';
export const CLOSE_PANEL = 'CLOSE_PANEL';
export const OPEN_PANELS = 'OPEN_PANELS'
export const UPDATE_OPEN_PANELS = 'UPDATE_OPEN_PANELS'
export const UPDATE_PANEL_POSITION = 'UPDATE_PANEL_POSITION';

export const DRAG = 'DRAG';
export const DROP = 'DROP';
export const FINISH_DND = 'FINISH_DND';

export const INIT_PERMISSIONS = 'INIT_PERMISSIONS';

export const SET_STEP_INDEX = 'SET_STEP_INDEX';
export const SET_RUN = 'SET_RUN';
export const SET_IS_MANUAL_INCREMENT = 'SET_IS_MANUAL_INCREMENT';
export const SET_IDENTIFIER = 'SET_IDENTIFIER';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';

/** Pushpin */
export const OPEN_PUSHPIN_DETAIL = "OPEN_PUSHPIN_DETAIL";
export const IS_PUSHPIN_COMMENT_ACTIVE = "IS_PUSHPIN_COMMENT_ACTIVE";
export const SELECTED_COMMENTS_FOR_PUSHPIN = "SELECTED_COMMENTS_FOR_PUSHPIN";
/** Endof pushpin */

interface SelectModelsAction {
  type: typeof SELECT_MODELS;
  payload: ViewerState;
}
interface SetModelOffsetAction {
  type: typeof SET_MODEL_OFFSET;
  payload: ViewerState;
}

interface SetViewerInstanceAction {
  type: typeof SET_VIEWER_INSTANCE;
  payload: ViewerState;
}

interface SetModelPropertiesAction {
  type: typeof SET_MODEL_PROPERTIES;
  payload: ViewerState;
}
interface ColorObjectsModelAction {
  type: typeof COLOR_OBJETS_MODEL;
  payload: ViewerState;
}
interface ColorBITagsAction {
  type: typeof COLOR_BI_TAGS;
  payload: ViewerState;
}
interface SelectTagsAction {
  type: typeof SELECT_TAGS;
  payload: ViewerState;
}

interface selectTagNamesAction {
  type: typeof SELECT_TAG_NAMES;
  payload: ViewerState;
}
interface SelectAggregateTagsAction {
  type: typeof SELECT_AGGREGATE_TAGS;
  payload: ViewerState;
}
interface IsolateTagsAction {
  type: typeof ISOLATE_TAGS;
  payload: ViewerState;
}

interface isolateTagNamesAction {
  type: typeof ISOLATE_TAG_NAMES;
  payload: ViewerState;
}

interface OpenPanelAction {
  type: typeof OPEN_PANEL;
  payload: IPanel;
}
interface UpdatePanelAction {
  type: typeof UPDATE_PANEL;
  payload: UpdatePanelPayload;
}
interface UpdatePanelPayload {
  panelId: string;
  data: any;
}
interface ClosePanelAction {
  type: typeof CLOSE_PANEL;
  payload: UpdatePanelPayload;
}

interface SelectObjectsAction {
  type: typeof SELECT_OBJECTS;
  payload: ViewerState;
}

interface IsolateObjectsAction {
  type: typeof ISOLATE_OBJECTS;
  payload: ViewerState;
}

interface SelectedObjectInViewerAction {
  type: typeof SELECTED_OBJECT_IN_VIEWER;
  payload: ViewerState;
}
interface CameraPostionChangedAction {
  type: typeof CAMERA_POSITION_CHANGED;
  payload: ViewerState;
}
interface ExtensionActivateAction {
  type: typeof EXTENSION_ACTIVE_CHANGED;
  payload: ViewerState;
}
interface ChangeCameraChangedAction {
  type: typeof CHANGE_POSITION_CAMERA;
  payload: ViewerState;
}

interface CreateSnapshotAction {
  type: typeof CREATE_SNAPSHOT;
  payload: ViewerState;
}

interface SaveViewerSettings {
  type: typeof SAVE_VIEWER_SETTINGS;
  payload: ViewerState;
}

interface ApplyViewerSettings {
  type: typeof APPLY_VIEWER_SETTINGS;
  payload: ViewerState;
}
interface SelectedStatusModesAction {
  type: typeof SELECTED_STATUS_MODES;
  payload: ViewerState;
}

interface SetCustomEntitiesColoredAction {
  type: typeof SET_CUSTOM_ENTITES_COLORED;
  payload: ViewerState;
}
interface CuplanesChangedAction {
  type: typeof CUTPLANES_CHANGED;
  payload: ViewerState;
}
interface SelectCuplanesAction {
  type: typeof SELECT_CUTPLANES;
  payload: ViewerState;
}

interface EditCuplanesAction {
  type: typeof EDIT_CUTPLANES;
  payload: ViewerState;
}

interface OpenBIReportAction {
  type: typeof OPEN_BI_REPORT;
  payload: ViewerState;
}
interface SetScreenShotAction {
  type: typeof SET_SCREENSHOT_ACTION;
  payload: ViewerState;
}

interface SetScreenShotUrlAction {
  type: typeof SET_SCREENSHOT_URL;
  payload: ViewerState;
}

interface SetMaxSelectionLevelAction {
  type: typeof SET_MAX_SELECTION_LEVEL;
  payload: ViewerState;
}

interface ChangeSelectionLevelAction {
  type: typeof CHANGE_SELECTION_LEVEL;
  payload: ViewerState;
}

interface SetIsMobileAction {
  type: typeof SET_IS_MOBILE;
  payload: ViewerState;
}
interface SetIsLessThanXSmall {
  type: typeof SET_IS_LESSTHANXSMALL;
  payload: ViewerState;
}
interface SetPanelScrollPositionAction {
  type: typeof SET_PANEL_SCROLL_POSITION;
  payload: ViewerState;
}
interface OpenPanelsAction {
  type: typeof OPEN_PANELS;
  payload: IPanel[];
}
interface UpdateOpenPanelsAction {
  type: typeof UPDATE_OPEN_PANELS;
  payload: IPanel[];
}

interface UpdatePanelPositionAction {
  type: typeof UPDATE_PANEL_POSITION;
  payload: {
    panelId: string;
    position: { left: string; top: string };
  };
}


export type ViewerActionType =
  | SelectModelsAction
  | SetModelOffsetAction
  | SetViewerInstanceAction
  | SetModelPropertiesAction
  | ColorObjectsModelAction
  | ColorBITagsAction
  | SelectObjectsAction
  | IsolateObjectsAction
  | SelectTagsAction
  | selectTagNamesAction
  | IsolateTagsAction
  | isolateTagNamesAction
  | OpenPanelAction
  | SelectedObjectInViewerAction
  | CameraPostionChangedAction
  | ExtensionActivateAction
  | ChangeCameraChangedAction
  | CreateSnapshotAction
  | SaveViewerSettings
  | ApplyViewerSettings
  | SelectAggregateTagsAction
  | SelectedStatusModesAction
  | SetCustomEntitiesColoredAction
  | CuplanesChangedAction
  | SelectCuplanesAction
  | EditCuplanesAction
  | OpenBIReportAction
  | SetScreenShotAction
  | SetScreenShotUrlAction
  | ChangeSelectionLevelAction
  | SetMaxSelectionLevelAction
  | SetIsMobileAction
  | SetIsLessThanXSmall
  | SetPanelScrollPositionAction
  | UpdatePanelAction
  | ClosePanelAction
  | OpenPanelsAction
  | UpdateOpenPanelsAction
  | UpdatePanelPositionAction;

interface ConfigAction {
  type: typeof INIT_CONFIG;
  payload: IConfigState;
}

export type ConfigActionType = ConfigAction;

interface DragAction {
  type: typeof DRAG;
  payload: IDndState;
}
interface DropAction {
  type: typeof DROP;
  payload: IDndState;
}
interface FinishDndAction {
  type: typeof FINISH_DND;
  payload: IDndState;
}

export type DndActionType = DragAction | DropAction | FinishDndAction;

interface InitPermissionsAction {
  type: typeof INIT_PERMISSIONS;
  payload: IPermissionsState;
}

export type PermissionActionType = InitPermissionsAction;

interface SelectIsolateWPAction {
  type: typeof SELECT_ISOLATE_WP;
  payload: IWPState;
}
interface SetPrevWP {
  type: typeof SET_PREV_WP;
  payload: IWPState;
}

export type WPActionType = SelectIsolateWPAction | SetPrevWP;

interface SelectIsolateCommentAction {
  type: typeof SELECT_ISOLATE_Comment;
  payload: ICommentState;
}
interface SetPrevComment {
  type: typeof SET_PREV_Comment;
  payload: ICommentState;
}

export type CommentActionType = SelectIsolateCommentAction | SetPrevComment;

interface SetStepIndexAction {
  type: typeof SET_STEP_INDEX;
  payload: ITourState;
}

interface SetRunAction {
  type: typeof SET_RUN;
  payload: ITourState;
}

interface SetIsManualIncrementAction {
  type: typeof SET_IS_MANUAL_INCREMENT;
  payload: ITourState;
}

interface SetIdentifierAction {
  type: typeof SET_IDENTIFIER;
  payload: ITourState;
}

/** Pushpin */
interface openPushpinAction {
  type: typeof OPEN_PUSHPIN_DETAIL;
  payload: IPushpinOpenData;
}
interface IisPushpinCommentActive {
  type: typeof IS_PUSHPIN_COMMENT_ACTIVE;
  payload: IPushpinCommentOpenData;
};

interface ISelectedCommentsForPushpin {
  type: typeof SELECTED_COMMENTS_FOR_PUSHPIN;
  payload: {
    comments : IPushpinComments[]
  };
};

export type PushpinOpenType = openPushpinAction | IisPushpinCommentActive | ISelectedCommentsForPushpin;
/** End of pushpin */

export type TourActionType = SetStepIndexAction | SetRunAction | SetIsManualIncrementAction | SetIdentifierAction;
