import { combineReducers } from "redux";
import { viewerReducer } from './viewerReducer';
import { configReducer } from './configReducer';
import { dndReducer } from './dndReducer';
import { rbacReducer } from './permissionReducer';
import { workpackageReducer } from './wpReducer';
import { tourReducer } from './tourReducer';
import { pushpinReducer } from "./puspinReducer";


const rootReducer = combineReducers({
    viewer: viewerReducer,
    config: configReducer,
    dnd: dndReducer,
    rbac: rbacReducer,
    wp: workpackageReducer,
    tour: tourReducer,
    pushpin: pushpinReducer
});

export default rootReducer; 
