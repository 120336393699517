import {
  IPlant,
  IUnit,
  IPlantUnitEntitiesColored,
  IAppConfig,
  IPanel,
  ITag,
  ICameraPosition,
  ITagAggregateId,
  IWorkPackage,
  IVolume,
  IBITag,
  IReport,
  IViewerSettings,
  IComment,
  IMultiplePKConfig
} from '../data-models/dataModels';
import {
  SELECT_MODELS,
  SET_MODEL_OFFSET,
  SET_VIEWER_INSTANCE,
  ViewerActionType,
  COLOR_OBJETS_MODEL,
  COLOR_BI_TAGS,
  ConfigActionType,
  INIT_CONFIG,
  SELECT_TAGS,
  SELECT_TAG_NAMES,
  ISOLATE_TAGS,
  ISOLATE_TAG_NAMES,
  OPEN_PANEL,
  SELECTED_OBJECT_IN_VIEWER,
  CAMERA_POSITION_CHANGED,
  EXTENSION_ACTIVE_CHANGED,
  CHANGE_POSITION_CAMERA,
  DRAG,
  DROP,
  FINISH_DND,
  DndActionType,
  SELECT_AGGREGATE_TAGS,
  SELECTED_STATUS_MODES,
  SET_CUSTOM_ENTITES_COLORED,
  INIT_PERMISSIONS,
  PermissionActionType,
  WPActionType,
  SELECT_ISOLATE_WP,
  SET_PREV_WP,
  CUTPLANES_CHANGED,
  SELECT_CUTPLANES,
  EDIT_CUTPLANES,
  OPEN_BI_REPORT,
  SET_SCREENSHOT_ACTION,
  SET_SCREENSHOT_URL,
  CHANGE_SELECTION_LEVEL,
  SET_MAX_SELECTION_LEVEL,
  CREATE_SNAPSHOT,
  SET_MODEL_PROPERTIES,
  SAVE_VIEWER_SETTINGS,
  APPLY_VIEWER_SETTINGS,
  SET_IS_MOBILE,
  SET_IS_LESSTHANXSMALL,
  CommentActionType,
  SELECT_ISOLATE_Comment,
  SET_PREV_Comment,
  SET_STEP_INDEX,
  SET_RUN,
  SET_IS_MANUAL_INCREMENT,
  SET_IDENTIFIER,
  TourActionType,
  SET_PANEL_SCROLL_POSITION,
  CLOSE_PANEL,
  OPEN_PANELS,
  UPDATE_OPEN_PANELS,
  UPDATE_PANEL_POSITION,
  SET_CURRENT_PROJECT,
  PushpinOpenType,
  OPEN_PUSHPIN_DETAIL,
  IS_PUSHPIN_COMMENT_ACTIVE,
  SELECTED_COMMENTS_FOR_PUSHPIN,
} from './actionTypes';
import { WorkpackageActionEnum, ScreenShotActionEnum ,CommentActionEnum, IPushpinComments} from './stateTypes';

export const selectModel = (
  plant: IPlant,
  units: IUnit[],
  selectedTagInViewer?: ITag[]
): ViewerActionType => {

  const filteredTags = units.length > 0 
    ? selectedTagInViewer?.filter((tag: ITag) =>
        tag.UnitName !== undefined && units.some(unit => unit.unitName === tag.UnitName)
      ) || []
    : [];

  return {
    type: SELECT_MODELS,
    payload: {
      selectedPlant: plant,
      selectedUnits: units,
      selectedTagInViewer: filteredTags,
    }
  };
};

export const saveViewerSettings = (
  viewerSettings: IViewerSettings | null
): ViewerActionType => ({
  type: SAVE_VIEWER_SETTINGS,
  payload: {
    viewerSettings: viewerSettings
  }
});

export const applyViewerSettings = (
  viewerSettings: IViewerSettings | null
): ViewerActionType => ({
  type: APPLY_VIEWER_SETTINGS,
  payload: {
    applyViewerSettings: viewerSettings
  }
});

export const setModelOffset = (
  offset: THREE.Vector3 | null
): ViewerActionType => ({
  type: SET_MODEL_OFFSET,
  payload: {
    globalOffset: offset
  }
});

export const setViewerInstance = (
  viewerInstance: IViewerComponent | null
): ViewerActionType => ({
  type: SET_VIEWER_INSTANCE,
  payload: {
    viewerInstance: viewerInstance
  }
});

export const setModelProperties = (
  properties: string[] | null
): ViewerActionType => ({
  type: SET_MODEL_PROPERTIES,
  payload: {
    properties
  }
});

export const colorObjects = (
  entitiesColored: IPlantUnitEntitiesColored[] | null
): ViewerActionType => ({
  type: COLOR_OBJETS_MODEL,
  payload: {
    entitiesColored
  }
});

export const colorBITags = (
  coloredBITags: IBITag[] | null,
  multiplePKConfig?: IMultiplePKConfig
): ViewerActionType => ({
  type: COLOR_BI_TAGS,
  payload: {
    coloredBITags,
    multiplePKConfig
  }
});

export const initConfig = (config: IAppConfig): ConfigActionType => ({
  type: INIT_CONFIG,
  payload: config
});
export const selectTags = (selectedTags: ITag[] | null): ViewerActionType => ({
  type: SELECT_TAGS,
  payload: {
    selectedTags
  }
});

export const selectTagNames = (
  selectedTagNames: string[] | null,
  selectedCheckbox?: boolean,
  multiplePKConfig?: IMultiplePKConfig
): ViewerActionType => ({
  type: SELECT_TAG_NAMES,
  payload: {
    selectedTagNames,
    selectedCheckbox,
    multiplePKConfig
  }
});

export const selectAggregateTags = (
  selectedAggregateTags: ITagAggregateId[]
): ViewerActionType => ({
  type: SELECT_AGGREGATE_TAGS,
  payload: {
    selectedAggregateTags
  }
});
export const isolateTags = (isolatedTags: ITag[] | null): ViewerActionType => ({
  type: ISOLATE_TAGS,
  payload: {
    isolatedTags
  }
});

export const isolateTagNames = (
  isolatedTagNames: string[] | null,
  multiplePKConfig?: IMultiplePKConfig
): ViewerActionType => ({
  type: ISOLATE_TAG_NAMES,
  payload: {
    isolatedTagNames,
    multiplePKConfig
  }
});

export const selectTagInViewer = (
  selectedTagInViewer: ITag[]
): ViewerActionType => ({
  type: SELECTED_OBJECT_IN_VIEWER,
  payload: {
    selectedTagInViewer
  }
});
export const openPanel = (openPanel: IPanel): ViewerActionType => ({
  type: OPEN_PANEL,
  payload: openPanel
});

export const updateOpenPanels = (panels: IPanel[]) => ({
  type: UPDATE_OPEN_PANELS,
  payload: panels,
});

export const openPanels = (openPanels: IPanel[]): ViewerActionType => ({
  type: OPEN_PANELS,
  payload: openPanels
});

export const closePanel = (panelId: string): ViewerActionType => ({
  type: CLOSE_PANEL,
  payload: { panelId, data: {} }
});

export const cameraPositionChanged = (
  cameraPosition: ICameraPosition
): ViewerActionType => ({
  type: CAMERA_POSITION_CHANGED,
  payload: {
    cameraPosition
  }
});
export const markupGuiActivated = (
  extension: boolean
): ViewerActionType => ({
  type: EXTENSION_ACTIVE_CHANGED,
  payload: {
    extension
  }
});
export const changePositionCamera = (
  changePositionCamera: ICameraPosition
): ViewerActionType => ({
  type: CHANGE_POSITION_CAMERA,
  payload: {
    changePositionCamera
  }
});

export const createSnapshot = (
  createdSnapshotId: number
): ViewerActionType => ({
  type: CREATE_SNAPSHOT,
  payload: {
    createdSnapshotId
  }
});

export const setScreenShotAction = (
  screenshotAction: ScreenShotActionEnum
): ViewerActionType => ({
  type: SET_SCREENSHOT_ACTION,
  payload: {
    screenshotAction
  }
});

export const setScreenShotUrl = (screenshotUrl: string): ViewerActionType => ({
  type: SET_SCREENSHOT_URL,
  payload: {
    screenshotUrl
  }
});

export const selectedStatusModes = (
  selectedStatusModes: string[]
): ViewerActionType => ({
  type: SELECTED_STATUS_MODES,
  payload: {
    selectedStatusModes
  }
});

export const setCustomEntitiesColored = (
  customEntitiesColored: IPlantUnitEntitiesColored[] | null
): ViewerActionType => ({
  type: SET_CUSTOM_ENTITES_COLORED,
  payload: {
    customEntitiesColored
  }
});

export const openBIReport = (
  openedBIReport: IReport | null
): ViewerActionType => ({
  type: OPEN_BI_REPORT,
  payload: {
    openedBIReport
  }
});

export const changeSelectionLevel = (
  selectionLevel: number
): ViewerActionType => ({
  type: CHANGE_SELECTION_LEVEL,
  payload: {
    selectionLevel
  }
});

export const setMaxSelectionLevel = (
  maxSelectionLevel: number
): ViewerActionType => ({
  type: SET_MAX_SELECTION_LEVEL,
  payload: {
    maxSelectionLevel
  }
});

export const drag = (dragType: string, item: any): DndActionType => ({
  type: DRAG,
  payload: {
    isDragging: true,
    dragType,
    item
  }
});
export const drop = (): DndActionType => ({
  type: DROP,
  payload: {
    isDragging: false
  }
});
export const finishDnd = (dropStatus: string): DndActionType => ({
  type: FINISH_DND,
  payload: {
    dropStatus
  }
});
export const initPermissions = (
  permissions: string[],
  username: string
): PermissionActionType => ({
  type: INIT_PERMISSIONS,
  payload: {
    permissions,
    username
  }
});
export const selectIsolateWP = (
  workpackage: IWorkPackage[] | [],
  workpackageAction?: WorkpackageActionEnum
): WPActionType => ({
  type: SELECT_ISOLATE_WP,
  payload: {
    workpackage: workpackage || [],
    workpackageAction
  }
});
export const setPrevWP = (
  workpackage: IWorkPackage[] | [],
  workpackageAction?: WorkpackageActionEnum
): WPActionType => ({
  type: SET_PREV_WP,
  payload: {
    workpackage: workpackage || [],
    workpackageAction
  }
});
export const cutplanesChanged = (
  cutplanes: THREE.Vector4[] | null
): ViewerActionType => ({
  type: CUTPLANES_CHANGED,
  payload: {
    cutplanes
  }
});
export const selectCutplanes = (
  selectedVolume: IVolume | null
): ViewerActionType => ({
  type: SELECT_CUTPLANES,
  payload: {
    selectedVolume
  }
});

export const editCutplanes = (
  editedVolume: IVolume | null
): ViewerActionType => ({
  type: EDIT_CUTPLANES,
  payload: {
    editedVolume
  }
});

export const setIsMobile = (
  isMobile: boolean
): ViewerActionType => ({
  type: SET_IS_MOBILE,
  payload: {
    isMobile
  }
});

export const setIsLessThanXSmall = (
  isLessThanXSmall: boolean
): ViewerActionType => ({
  type: SET_IS_LESSTHANXSMALL,
  payload: {
    isLessThanXSmall
  }
});

export const setPanelScrollPosition = (
  containerId: string,
  scrollPosition: number
  ) => ({
  type: SET_PANEL_SCROLL_POSITION,
  payload: {
    containerId,
    scrollPosition,  
  }
});


export const selectIsolateComment = (
  comment: IComment | null,
  CommentAction?: CommentActionEnum
): CommentActionType => ({
  type: SELECT_ISOLATE_Comment,
  payload: {
    comment: comment || undefined,
    CommentAction
  }
});
export const setPrevComment = (
  comment: IComment | null,
  CommentAction?: CommentActionEnum
): CommentActionType => ({
  type: SET_PREV_Comment,
  payload: {
    comment: comment || undefined,
    CommentAction
  }
});

export const setStepIndex = (
  stepIndex: number
): TourActionType => ({
  type: SET_STEP_INDEX,
  payload: {
    stepIndex
  }
});

export const setRun = (
  run: boolean
): TourActionType => ({
  type: SET_RUN,
  payload: {
    run
  }
});

export const setIsManualIncrement = (
  isManualIncrement: boolean
): TourActionType => ({
  type: SET_IS_MANUAL_INCREMENT,
  payload: {
    isManualIncrement
  }
});

export const setIdentifier = (
  identifier: string | null
): TourActionType => ({
  type: SET_IDENTIFIER,
  payload: {
    identifier
  }
});

export const updatePanelPosition = (panelId: string, position: { left: string, top: string }): ViewerActionType => ({
  type: UPDATE_PANEL_POSITION,
  payload: {
    panelId,
    position,
  }
});

/** Pushpin Actions */
export const openPushpinDetailPanel = (
  tag: string,
  entityId: string,
  uniqueId?: string,
  forgeUrn? : string,
  propertyKey? : string,
  tagByEntityId? : string
): PushpinOpenType => ({
  type: OPEN_PUSHPIN_DETAIL,
  payload: {
    tag,
    entityId,
    uniqueId,
    propertyKey,
    forgeUrn,
    tagByEntityId
  }
});

export const isPushpinCommentActive = (
  {isPushpinCommentActive} : {isPushpinCommentActive: boolean}
): PushpinOpenType => ({
  type : IS_PUSHPIN_COMMENT_ACTIVE,
  payload: {
    isPushpinCommentActive
  }
});

export const selectedCommentsForPushpin = (
  comments : IPushpinComments[]
): PushpinOpenType => ({
  type : SELECTED_COMMENTS_FOR_PUSHPIN,
  payload: {
    comments: comments
  }
})
/** Endof pushpin Actions */