import { IS_PUSHPIN_COMMENT_ACTIVE, OPEN_PUSHPIN_DETAIL, PushpinOpenType, SELECTED_COMMENTS_FOR_PUSHPIN } from '../actionTypes';
import { IPushpinOpenData } from '../stateTypes';

const initialState: IPushpinOpenData = {
    entityId: null,
    tag: null,
    propertyKey: null
};

export function pushpinReducer(
  state = initialState,
  action: PushpinOpenType
): IPushpinOpenData {
  if (action.type === OPEN_PUSHPIN_DETAIL) {
    return Object.assign({}, state, {
        entityId: action.payload.entityId,
        tag: action.payload.tag,
        uniqueId : action.payload.uniqueId,
        propertyKey : action.payload.propertyKey,
        forgeUrn : action.payload.forgeUrn,
        tagByEntityId : action.payload.tagByEntityId
    });
  }
  if(action.type === IS_PUSHPIN_COMMENT_ACTIVE){
    return Object.assign({}, state, {
      isPushpinCommentActive: action.payload.isPushpinCommentActive,
    });
  }
  if(action.type === SELECTED_COMMENTS_FOR_PUSHPIN){
    return Object.assign({}, state, {
      comments: action.payload.comments,
    });
  }
  return state;
}
